export default function configureNewsFilter($el) {
  if (!$el) {
    return;
  }

  let activeTag = 'all';
  const newsPosts = Array.from(document.querySelectorAll('.NewsSummary'));
  const filterLinks = Array.from(document.querySelectorAll('.NewsFilter-tag'));

  function handleTagClick(e) {
    e.preventDefault();
    activeTag = e.target.dataset.filter;
    render();
  }

  function render() {
    filterLinks.forEach(el => {
      el.classList.remove('NewsFilter-tagActive');
    });

    const activeFilterLink = filterLinks.find(
      el => el.dataset.filter === activeTag
    );
    if (activeFilterLink) {
      activeFilterLink.classList.add('NewsFilter-tagActive');
    }

    newsPosts.forEach(el => {
      el.classList.remove('hidden');

      if (activeTag === 'all') {
        return;
      } else if (el.dataset.category !== activeTag) {
        el.classList.add('hidden');
      }
    });
  }

  filterLinks.forEach(el => {
    el.addEventListener('click', handleTagClick);
  });
}

import '../css/index.css';
import configureMenu from './menu';
import configureProjects from './projects';
import configureNewsFilter from './news-filter';

let hasInit = false;
function init() {
  if (hasInit) {
    return;
  }
  hasInit = true;
  configureMenu();
  configureProjects();
  configureNewsFilter(document.querySelector('.js-news-filter'));
}

document.addEventListener('DOMContentLoaded', init);
window.addEventListener('load', init);

export default function(root, projects, onMapFeatureClick = () => {}) {
  if (!root) {
    return;
  }

  if (!mapboxgl) {
    return;
  }

  mapboxgl.accessToken =
    'pk.eyJ1Ijoicm9zc3p1cm93c2tpIiwiYSI6Ijc0YTkwZjMyYjc2YmI0MjlkZDhkMTAzNWY2MDA1MzYwIn0.klSpcbjgQgg2MLJc9PIwig';

  const map = new mapboxgl.Map({
    container: root,
    style: 'mapbox://styles/mapbox/light-v10',
    center: [-30, 25],
    zoom: 1.5,
    scrollZoom: false,
    maxZoom: 4.5,
    dragRotate: false
  });

  const nav = new mapboxgl.NavigationControl({ showCompass: false });
  map.addControl(nav, 'bottom-right');
  const noop = () => {
    // no-op until map is ready
  };
  window.targetMapProject = noop;
  window.updateMapProjects = noop;

  return new Promise((resolve, reject) => {
    map.on('load', () => {
      map.addSource('projects', convertProjectsToGeoJSON(projects));
      map.addLayer({
        id: 'project-dots',
        type: 'circle',
        source: 'projects',
        paint: {
          'circle-radius': 6,
          'circle-color': '#f9b863'
        },
        filter: ['==', '$type', 'Point']
      });

      const popup = new mapboxgl.Popup({
        closeButton: false,
        closeOnClick: false
      });

      map.on('mouseenter', 'project-dots', e => {
        map.getCanvas().style.cursor = 'pointer';
        let coordinates = e.features[0].geometry.coordinates.slice();
        const title = e.features[0].properties.title;

        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        popup
          .setLngLat(coordinates)
          .setHTML(`<span>${title}</span>`)
          .addTo(map);
      });

      map.on('click', 'project-dots', function(e) {
        map.flyTo({ center: e.features[0].geometry.coordinates });
        onMapFeatureClick(e.features[0].properties);
      });

      map.on('mouseleave', 'project-dots', () => {
        map.getCanvas().style.cursor = '';
        popup.remove();
      });

      window.targetMapProject = (projectId = null) => {
        if (projectId == null) {
          return;
        }
        const features = map.querySourceFeatures('projects', {
          sourceLayer: 'project-dots',
          filter: ['==', 'id', projectId]
        })
        if (features.length < 1) {
          return;
        }
        const target = features[0];
        if (target && target.geometry && target.geometry.coordinates) {
          map.flyTo({ center: target.geometry.coordinates });
        }
      }

      window.updateMapProjects = (projectIds = []) => {
        map.setFilter('project-dots', [
          'match',
          ['get', 'id'],
          projectIds,
          true,
          false
        ]);
      }

      resolve();

      setTimeout(() => {
        map.getSource('projects').setData(convertProjectsToGeoJSON([]));
      }, 5000);
    });

    map.on('error', () => {
      reject();
    });
  });
}

function convertProjectsToGeoJSON(projects) {
  const geojson = {
    type: 'geojson',
    data: {
      type: 'FeatureCollection'
    }
  };

  geojson.data.features = projects
    .filter(project => Boolean(project.coordinates))
    .map(project => ({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: project.coordinates.split(',').reverse()
      },
      properties: {
        id: project.id,
        title: project.title,
        location: project.location,
        minerals: project.minerals.join(', ')
      }
    }));

  return geojson;
}

export function oxfordComma(arr) {
  const conjunction = 'and';

  if (arr.length < 1) {
    return '';
  }

  if (arr.length < 2) {
    return arr[0];
  }

  if (arr.length < 3) {
    return arr.join(` ${conjunction} `);
  }

  const alt = arr.slice();
  alt[arr.length - 1] = `${conjunction} ${arr[arr.length - 1]}`;
  return alt.join(', ');
}

export function sortByFrequencyAndRemoveDuplicates(array, minimumCount = 1) {
  let frequency = {};
  let value;

  for (let i = 0; i < array.length; i++) {
    value = array[i];
    if (value in frequency) {
      frequency[value]++;
    } else {
      frequency[value] = 1;
    }
  }

  // make array from the frequency object to de-duplicate
  const uniques = [];
  for (value in frequency) {
    if (frequency[value] < minimumCount) {
      continue;
    }
    uniques.push(value);
  }

  // sort the uniques array in descending order by frequency
  function compareFrequency(a, b) {
    return frequency[b] - frequency[a];
  }

  return uniques.sort(compareFrequency);
}

export function arrayIntersection(a, b) {
  return a.filter(x => b.includes(x));
}

export function arrayDifference(a, b) {
  return a.filter(x => !b.includes(x));
}

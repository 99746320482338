const Actions = {
  OPEN_MENU: 'open_menu',
  CLOSE_MENU: 'close_menu',
};

export default function() {
  const $link = document.querySelector('.js-menu-link');
  const $menu = document.querySelector('.js-menu');

  if (!$link || !$menu) {
    console.warn('Could not find .js-menu-link or .js-menu elements');
    return;
  }

  const ESC_KEY = 27;
  let timeoutId = null;

  function handleMenuClick(e) {
    e.preventDefault();
    render(Actions.OPEN_MENU);
  }

  function handleOverlayClick(e) {
    e.preventDefault();
    render(Actions.CLOSE_MENU);
  }

  function handleKeydown(e) {
    if (e.which === ESC_KEY) {
      render(Actions.CLOSE_MENU);
    }
  }

  function render(action) {
    clearTimeout(timeoutId);
    switch (action) {
      case Actions.CLOSE_MENU:
        $menu.classList.remove('MobileNavigation--active');
        document.body.classList.remove('nav-is-open');
        timeoutId = setTimeout(() => {
          $menu.classList.remove('MobileNavigation--activeEnter');
        }, 400);
        window.removeEventListener('keydown', handleKeydown);
        break;
      case Actions.OPEN_MENU:
        $menu.classList.add('MobileNavigation--activeEnter');
        document.body.classList.add('nav-is-open');
        window.addEventListener('keydown', handleKeydown);
        timeoutId = setTimeout(() => {
          $menu.classList.add('MobileNavigation--active');
        }, 15);
      default:
        break;
    }
  }

  $link.addEventListener('click', handleMenuClick);
  $menu
    .querySelector('.js-menu-overlay')
    .addEventListener('click', handleOverlayClick);
}

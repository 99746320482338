import { render, h } from 'preact';
import ProjectList from './components/project-list';
import configureMap from './map';
import anime from 'animejs/lib/anime.es';

export default function configureProjectFilter() {
  const rootEl = document.querySelector('.js-project-list');
  const dataEl = document.querySelector('.js-project-list-data');
  const mapRootEl = document.querySelector('.js-map');

  if (!rootEl || !dataEl) {
    return;
  }

  const data = JSON.parse(dataEl.textContent);
  const projects = data.projects;
  const scrollingEl = document.scrollingElement;

  configureMap(mapRootEl, projects, project => {
    const focusedEl = document.querySelector(`[data-id="${project.id}"]`);
    if (focusedEl && scrollingEl) {
      const rect = focusedEl.getBoundingClientRect();
      const elementTop = rect.top - window.innerHeight / 2;

      focusedEl.classList.add('ProjectGrid-row--focused');
      const animation = anime({
        targets: [scrollingEl],
        scrollTop: '+=' + elementTop,
        duration: 500,
        endDelay: 250,
        easing: 'easeOutQuint'
      });

      animation.finished.then(() => {
        focusedEl.classList.remove('ProjectGrid-row--focused');
      });
    }
  });

  function handleFilterChange(projectIds) {
    updateMapProjects(projectIds);
  }

  render(
    <ProjectList projects={projects} onFilterChange={handleFilterChange} />,
    rootEl
  );
  rootEl.classList.add('ProjectList--loaded');
}

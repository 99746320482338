import { Fragment, h } from 'preact';
import { useState, useMemo } from 'preact/hooks';
import {
  oxfordComma,
  sortByFrequencyAndRemoveDuplicates,
  arrayIntersection,
  arrayDifference
} from '../utils';

const OTHER_VALUE = '__other_value';
const OTHER_MINERALS_VALUES = ['Various Minerals'];
const noop = () => {};

function removeUpdate (str) {
  return str.replace(/\s+update$/i, '').trim();
}

function removeServicesWithUpdate (arr) {
  return arr.filter(str => {
    return !(str.endsWith('Update') && arr.includes(removeUpdate(str)));
  })
}

export default function ProjectList(props) {
  const { projects, onFilterChange = noop } = props;

  const [currentMineral, setMineral] = useState(null);
  const [currentService, setService] = useState(null);

  const allProjects = props.projects;
  const allServices = allProjects.reduce((a, b) => a.concat(b.scope), []);
  const allMinerals = allProjects.reduce((a, b) => a.concat(b.minerals), []);

  const services = removeServicesWithUpdate(sortByFrequencyAndRemoveDuplicates(allServices, 2)).sort();
  const otherServices = arrayDifference(allServices, services);
  const minerals = sortByFrequencyAndRemoveDuplicates(allMinerals, 2).filter(
    v => !OTHER_MINERALS_VALUES.includes(v)
  );
  const otherMinerals = arrayDifference(allMinerals, minerals).concat(
    OTHER_MINERALS_VALUES
  );

  const filteredProjects = allProjects.filter(project => {
    if (currentMineral) {
      const isOther =
        currentMineral === OTHER_VALUE &&
        project.minerals.some(val => otherMinerals.includes(val));
      const isCurrent = project.minerals.includes(currentMineral);

      if (!isOther && !isCurrent) {
        return false;
      }
    }

    if (currentService) {
      const isOther =
        currentService === OTHER_VALUE &&
        project.scope.some(val => otherServices.includes(val));
      const isCurrent = project.scope.map(removeUpdate).includes(currentService);

      if (!isOther && !isCurrent) {
        return false;
      }
    }

    return true;
  });

  onFilterChange(filteredProjects.map(p => p.id));

  return (
    <section>
      <nav class="x xd-column xj-spaceBetween xw-wrap xa-center fs-small pt-4 mb-4">
        <div class="x-s xa-center ProjectFilter-dropdowns">
          <select
            class="ProjectFilter js-project-filter-service"
            value={currentService}
            onInput={e => {
              setService(e.target.value);
              setMineral(null);
            }}
          >
            <option value={null}>All Services</option>
            {services.map(service => (
              <option value={service}>{service}</option>
            ))}
            <option value={OTHER_VALUE}>Others</option>
          </select>
          <select
            class="ProjectFilter js-project-filter-mineral"
            value={currentMineral}
            onInput={e => {
              setService(null);
              setMineral(e.target.value);
            }}
          >
            <option value={null}>All Minerals</option>
            {minerals.map(mineral => (
              <option value={mineral}>{mineral}</option>
            ))}
            <option value={OTHER_VALUE}>Others</option>
          </select>
        </div>
        {(currentService || currentMineral) && (
          <div class="ProjectFilter-clear">
            <a
              href="#"
              onClick={e => {
                e.preventDefault();
                setService(null);
                setMineral(null);
              }}
            >
              Clear Filters
            </a>
          </div>
        )}
      </nav>
      <div class="ProjectGrid mb-5 fs-small js-project-list">
        <div class="ProjectGrid-header f-caps">
          <div>Project</div>
          <div>Client</div>
          <div class="ProjectGrid-scope">Scope</div>
          <div class="ProjectGrid-minerals">Minerals</div>
        </div>
        {filteredProjects.map(project => (
          <ProjectRow project={project} />
        ))}
      </div>
    </section>
  );
}

function ProjectRow (props) {
  const project = props.project;

  function handleClick(e) {
    e.preventDefault();
    targetMapProject(project.id);
  }

  return (
    <div class="ProjectGrid-row" data-id={project.id} datetime={project.date}>
    <div class="ProjectGrid-projectName p-relative">
      {project.coordinates ? (
        <a class="ProjectGrid-projectNameLink stretched-link" href="#" onClick={handleClick}>{project.title}</a>
      ) : (
        <span class="ProjectGrid-projectNameSpan">{project.title}</span>
      )}
      {project.location && (
        <Fragment>
          <br />
          <span class={project.coordinates ? 'ProjectGrid-projectNameFadedLink' : 'c-gray'}>{project.location}</span>
        </Fragment>
      )}
    </div>
      <div class="ProjectGrid-client">
        <span>{project.client}</span>
      </div>
      <div class="ProjectGrid-scope">
        <ul>
          {project.scope.map(item => (
            <li>{item}</li>
          ))}
        </ul>
      </div>
      <div class="ProjectGrid-minerals">
        {oxfordComma(project.minerals)}
      </div>
    </div>
  );
}
